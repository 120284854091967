<template>
  <div class="auth-wrapper auth-v1">

    <div class="" style="position: fixed; right: 30px; top: 30px;">
      <theme-switcher></theme-switcher>
    </div>

    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="#"
            class=""
          >
            <v-row class="text-center">
              <v-col class="d-flex justify-center" cols="12">

                <v-img
                  :src="`${this.logo}`"
                  max-height="80px"
                  alt="logo"
                  contain
                  class="me-3 d-flex justify-center"
                ></v-img>

            </v-col>

            <v-col cols="12" sm="12" v-if="this.logo == ''">
              <h2 class="text-2xl font-weight-semibold">
               {{ imobiliaria }}
            </h2>
            </v-col>
            </v-row>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-1">
          </p>
          <p class="mb-2 text-center">
            Por favor informe seu login para continuar
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form @submit.stop.prevent="submit">
            <v-text-field
              v-model="cpf_cnpj"
              outlined
              label="CPF/CNPJ"
              placeholder="000.000.000-00"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="senha"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Senha"
              placeholder="········"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap mt-3">

              <v-dialog
              transition="dialog-top-transition"
              max-width="600"
              v-model="dialog">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    href="javascript:void(0)"
                    class="mt-1"
                    v-bind="attrs"
                        v-on="on"
                  >
                    Esqueceu a senha?
                  </a>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      color="primary"
                    >
                      <span class="m-5" style="color: white;">Digite seu CPF/CNPJ e enviaremos uma senha provisória para seu e-mail</span>
                    </v-toolbar>
                    <v-card-text class="mt-2">
                      <v-form @submit.stop.prevent="submit" class="mt-5">
                        <v-text-field
                          v-model="m_cpf_cnpj"
                          :prepend-inner-icon="icons.mdiAccountOutline"
                          label="CPF/CNPJ"
                          outlined
                          dense
                          placeholder="000.000.000-00"
                        ></v-text-field>
                        <v-btn color="primary"
                        @click="recuperarSenha()">
                          Enviar
                        </v-btn>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                      >Cancelar</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
            >
              Entrar
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Primeiro acesso ?
          </span>
          <router-link :to="{ name:'pages-register' }">
            Crie um acesso aqui
          </router-link>
        </v-card-text>

        <!-- divider -->
        <!-- social links -->
      </v-card>
    </div>

    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
    >
      {{ this.toastText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

     <!-- tree
     <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/customer.png"
    ></v-img>-->

    <!-- tree
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/customer-2.png"
    ></v-img>-->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Strings from './../../common/strings'
import UsuarioStore from './../../store/Usuario'
import Cookie from 'js-cookie'
import ThemeSwitcher from '../../layouts/components/ThemeSwitcher.vue'

export default {
  components:{
    ThemeSwitcher
  },
  mounted: function(){
  },
  data(){
    return {
      imobiliaria: process.env.VUE_APP_INCORPORATION_NAME,
      logo: process.env.VUE_APP_URL_LOGO,
      dialog: false
    }
  },
  setup() {
    const isPasswordVisible = ref(false)
    const cpf_cnpj = ref('')
    const m_cpf_cnpj = ref('')
    const senha = ref('')
    const snackbar = ref(false)
    const toastText = ref('')
    const logo = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      cpf_cnpj,
      senha,
      m_cpf_cnpj,
      socialLink,
      snackbar,
      toastText,
      logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    submit(){
        this.$loading(true)

        let cpf_cnpj_without_chars = this.cpf_cnpj.replace(/[^\d]+/g, '')

        if(cpf_cnpj_without_chars == ''){
            this.dialog = false
            this.toastText = 'Informe corretamente o CPF/CNPJ'
            this.snackbar = true
            this.$loading(false)
            return
        }

        UsuarioStore.login(cpf_cnpj_without_chars, this.senha, Strings.imobiliariaId).then(result =>{
            Cookie.set(Strings.cookie_token, result.data.TokenSession)
            Cookie.set(Strings.cookie_username, result.data.Nome)
            this.$loading(false)
            this.$router.push({name: "portal"})
        }).catch(error => {
            console.log(error)
            this.toastText = 'Erro ao fazer o login'
            this.snackbar = true
        }).finally(() => {
          this.$loading(false)
        })
    },
    recuperarSenha(){

      if(this.m_cpf_cnpj == null || this.m_cpf_cnpj == ''){
        this.dialog = false
        this.toastText = 'Informe corretamente o CPF/CNPJ'
        this.snackbar = true
        this.$loading(false)
        return
      }

      this.$loading(true)

      let cpf_cnpj_without_chars = this.cpf_cnpj.replace(/[^\d]+/g, '')

      if(cpf_cnpj_without_chars == ''){
          this.dialog = false
          this.toastText = 'Informe corretamente o CPF/CNPJ'
          this.snackbar = true
          this.$loading(false)
          return
      }

      UsuarioStore.recuperarSenha(cpf_cnpj_without_chars, Strings.imobiliariaId).then(result => {

        if(result.data.includes('Não foi possível')){
          this.toastText = result.data
          this.snackbar = true
          this.dialog = false
        }else{
          this.toastText = 'Sua senha provisória foi enviada para o seu email: ' + result.data
          this.snackbar = true
          this.dialog = false
        }
      }).catch( error => {
        this.toastText = 'Erro ao tentar recuperar senha'
        this.snackbar = true
        console.log(error)
      }).finally(() => {
        this.dialog = false
        this.$loading(false)
        this.m_cpf_cnpj = ''
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
